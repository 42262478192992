<template class="temp">
  <video ref="film" id="film" :src="film" type="video/mp4" autoplay muted loop playsinline></video>
  <i @click="ton" id="soundbutton" ref="soundbutton" class="bi bi-volume-up-fill"></i>
  <div class="backNew">
    <div id="platzhalter"></div>
    <div class="container-fluid justify-content-center align-items-center">
      <div class="row justify-content-center align-content-center">
        <div class="col col-12">
          <h1 class="colorfull">Was verraten deine Daten über dich?</h1>
        </div>
        <div class="col col-8">
          <p class="reading anweisung">Diese Anwendung bietet dir die Möglichkeit,
            deinen eigenen Datensatz zu erleben.
            Dabei wird dein Google-Suchverlauf ausgewertet.
            <br><br>
            Du hast jetzt die Möglichkeit deine Daten bei Google zu beantragen.
            Unten findest du eine Anleitung, wie du deinen Datensatz innerhalb weniger
            Minuten herunterladen kannst.
            Alternativ kannst du die Anwendung
            mit einem Beispiel-Datensatz erleben.
            <br><br>
            Ich empfehle dir, es mit deinen eigenen Daten auszuprobieren.
          </p>
        </div>
        <div class="col col-12">
          <p class="miniText"><strong>Hinweis:</strong> Es werden
            keine Daten von dir gespeichert, sobald du die Seite verlässt sind alle Daten weg.</p>
        </div>
        <div class="col col-12 justify-content-around">
          <button @click="withNonData" class="btn">Daten erleben</button>
          <button @click="goUpload" class="btn">Anleitung</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// eslint-disable-next-line import/extensions

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  /* eslint-disable */
  name: 'about',
  components: {},
  mounted() {

    gsap.registerPlugin(ScrollTrigger);

    let timeline = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: '#platzhalter',
        start: 'top top', // top center bottom  20px 80%
        end: 'bottom bottom', // * 1.1, // end after scrolling 500px beyond the start
        markers: false,
        scrub: 0,
        onEnter: () => this.play(),
        onEnterBack: () => this.play(),
        onLeave: () => this.pausa(),
        //onLeaveBack: () => this.pausa(),
      }
    });
    timeline.addLabel('start')
      // Show 1 Example
      .fromTo('.container-fluid', {
        opacity: 0,
      }, {
        opacity: 1,
      })

    this.timeline = timeline;
  },
  methods: {
    goUpload() {
      this.$router.push('/anleitung');
    },
    withNonData() {
      this.$router.push({
        name: 'Reading',
        params: {

          dataVorhanden: 0,
          reflect: [37, 26, 12, 10, 6, 5, 4],

          fakten: [6589, 'Samstag 07. März. 2020', 'Mittwoch 19. Okt. 2021', 11, 330],
          faktenDiagramm: [80, 60, 54, 62, 74, 37, 72, 82, 90, 92, 85, 100, 93, 89, 84, 81, 76, 79, 81, 85],
          faktenDiagramm2: ['März. 2020', 'April. 2020', 'Mai. 2020', 'Juni. 2020', 'Juli. 2020', 'Aug. 2020', 'Sept. 2020', 'Okt. 2020', 'Nov. 2020', 'Dez. 2020', 'Jan. 2021', 'Feb. 2021', 'März. 2021', 'April. 2021', 'Mai. 2021', 'Juni. 2021', 'Juli. 2021', 'Aug. 2021', 'Sept. 2021', 'Okt. 2021'],
          faktenMost5: this.faktenMost5,
          faktenSuche: this.faktenSuche,
          faktenSuche2: this.faktenSuche2,
          faktenSuche3: this.faktenSuche3,

          tagesablauf: [16, 6, 23, 5, 0, 4],
          tagesablaufDiagramm: [5, 0, 1, 2, 10, 12, 20, 38, 52, 59, 79, 86, 81, 84, 92, 100, 84, 83, 82, 74, 51, 33, 16, 0],

          InteressenMost5: this.InteressenMost5,
          NahrungMost5: this.NahrungMost5,
          UnterhaltungMost5: this.UnterhaltungMost5,
          ReisenMost5: this.ReisenMost5,
          WissenMost5: this.WissenMost5,
          ShoppingMost5: this.ShoppingMost5,
          GesundheitMost5: this.GesundheitMost5,

          searches: this.searches,

        }
      });
    },

    play() {

      let playPromise =  this.$refs.film.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
          this.$refs.film.play();
        })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    },

    pausa() {
      let playPromise =  this.$refs.film.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
          this.$refs.film.pause();
        })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    },
    ton() {
      let sound = this.$refs.film;
      if (sound.muted) {
        sound.muted = false;
        this.$refs.soundbutton.classList.add('unsichtbar');
      } else {
        sound.muted = true;
        this.$refs.soundbutton.classList.remove('unsichtbar');
      }
    },

  },

  data() {
    return {
      InteressenMost5: [
        'mercedes',
        'unity',
        'porsche',
        'mail',
        'elster',
        'zulassung',
        'apple',
        'arduino',
        'nba',
        'speedtest',
        'hero',
        'audi',
        'one',
        'dji',
        'blackmagic',
        'ronin',
        'free',
        'lamborghini',
        'benz',
        'fpv',
        'mavic',
        'pro',
        'feuerwehr',
        'gopro',
        'mac',
        'adobe',
        'black',
        'wiki',
        'regensburg',
        'apple',
        'macbook',
        'golf',
        '4k',
        'porsche',
        'iphone',
        'design',
        'bmw',
        'nvidea',
        'macbook',
        'camera',
        'singen',
        'angeln',
        'oneplus',
        'webdesign',
        'malen',
        'federball',
        'tanzen',
        'camera',
        'garten',
        'vogelhaus',
      ],
      NahrungMost5: [
        'mc',
        'donalds',
        'ricetta',
        'tandoori',
        'chicken',
        'kalorien',
        'post',
        'restaurants',
        'restaurant',
        'kneitinger',
        'garten',
        'leichte',
        'max',
        'und',
        'muh',
        'regensburg',
        'sushi',
        'berlin',
        'subway',
        'soße',
        'augsburg',
        'schokokuchen',
        'low',
        'carb',
        'pfannkuchen',
        'curry',
        'rezepte',
        'gewürz',
        'lieferando',
        'oslo',
        'vegan',
        'tortenboden',
        'spareribs',
        'pizza',
        'metzgerei',
        'mooser',
        'rezept',
        'chefkoch',
        'schokolade',
        'döner',
        'bäcker',
        'reis',
        'burger',
        'boxbote',
        'tee',
        'vodka',
        'münchen',
        'stuttgart',
        'staupitopia',
        'pasta',
        'wirtshaus',
        'schaller',
        'plätzchen',
        'ogawa',
        'kartoffeln',
        'goldener',
        'bäckerei',
        'schifferl',
        'selber',
        'machen',
        'süßkartoffel',
        'ersatz',
        'vegane',
        'vanillekipferl',
        'pizzeria',
        'spätzle',
        'forsthofgut',
        'kaputte'],
      UnterhaltungMost5: [
        'lyrics',
        'filme',
        'passenger',
        'kendrick',
        'lamar',
        'livestream',
        'warfare',
        'eminem',
        'party',
        'game',
        'thrones',
        'video',
        'film',
        'spiele',
        'youtube',
        'music',
        'league',
        'the',
        'weeknd',
        'kendrick',
        'asap',
        'rocky',
        'of',
        'lyrics',
        'best',
        'game',
        'drake',
        'for',
        'live',
        'stream',
        'vimeo',
        'black',
        'lied',
        'neo',
        'royal',
        'song',
        'genius',
        'getting',
        'schoolboy',
        'warcraft',
        'steam',
        'vimeo',
        'black',
        'one',
        'der',
        'lied',
        'mediathek',
        'video',
        'neo',
        'royal',
        'song'],
      WissenMost5: [
        'wetter',
        'morgen',
        'nba',
        'schedule',
        'regensburg',
        'deutschland',
        'corona',
        'heute',
        'aktuell',
        'in',
        'wetter.com',
        'münchen',
        'wie',
        'italien',
        'tagesschau',
        '2015',
        'nachrichten',
        'bayern',
        'stuttgart',
        'dollar',
        'kurs',
        'augsburg',
        'online',
        'windows',
        'was',
        'mac',
        'einwohner',
        'welche',
        'deutschland',
        'englisch',
        'übersetzer',
        'der',
        'euro',
        'viel',
        'bedeutung',
        'für',
        'auf',
        'italienisch',
        'hat',
        'in',
        '10',
        'google',
        'to',
        'kann',
        'deutsch',
        'regensburg',
        'man',
        'wann',
        'das',
        'lexica',
        'duden'
      ],
      ReisenMost5: [
        'eurowings',
        'bodensee',
        'schweiz',
        'san',
        'flüge',
        'zugticket',
        'dominikanische',
        'republik',
        'hotel',
        'db',
        'ticket',
        'amsterdam',
        'zugverspätung',
        'geld',
        'zurück',
        'flüge',
        'von',
        'münchen',
        'nach',
        'brindisi',
        'am',
        'rückflug',
        'hotel',
        'hotels',
        'regensburg',
        'skyscanner',
        'lufthansa',
        'online',
        'italien',
        'golf',
        'airbus',
        'baden',
        'der',
        'straße',
        'münchen,',
        'deutschland',
        'brindisi,',
        'mallorca',
        'club',
        'air',
        'berlin',
        'db',
        'flug',
        'gate',
        'strand',
        'sehenswürdigkeiten',
        'riga',
        'österreich',
        'frankfurt'],
      ShoppingMost5: [
        'flohmarktkalender',
        'damen',
        'gutschein',
        'bestellen',
        'amazon.de',
        'kaufen',
        'amazon',
        'mieten',
        'gopro',
        'conrad',
        'rewe',
        'samsung',
        'angebote',
        'verkaufen',
        'preisvergleich',
        'regensburg',
        'geschäft',
        'ikea',
        'sony',
        'ebay',
        'preis',
        'bauhaus',
        'kosten',
        'münchen',
        'herren',
        'online',
        'lieferdatum',
        'shop',
        'saturn',
        'markt',
        'prospekt',
        'produkte',
        'händler',
        'onlineshop',
        'dm',
        'objektive',
        'media',
        'und',
        'autoscout',
        '24',
        'bestellen',
        'preise',
        'rent',
        'vergleich',
      ],
      GesundheitMost5: ['Schlafstörungen', 'Depression', 'Diabetes'],

      faktenMost5: ['film', 'hotel', 'windows', 'mieten', 'whatsapp', 'time', 'music'],
      faktenSuche: ['film stills warme lichtstimmung ',
        'film equipment mieten regensburg ',
        'filme die ab 16 sind aber schon 12 jährige sehen können',
        'film bilder leinwand ',
        'seven film ',
        'filmograf film',
        'paolo sorrentino neuer film ',
        'film filmlook pictures ',
        'flagge film selber machen ',
        'westend film ',
        'film akademie ludwigsburg bewerbungsaufgaben ',
        'led film licht ',
        'neue filme mit the rock ',
        'effanterible film fassbinder roehler ',
        'fassbinder film 2020 teaser ',
        'best boy film ',
        'film i p ',
        'glücksspirale film award ',
        'passenger film ',
        'best boy film ',
        'effanterible film fassbinder roehler ',
        'film porno gratis ',
        'canon film kamera ',
        'harrypotter film',
        'film plakat ',
        'herbst in new york film ',
        'google filme ',
        'film jobs',
        'sony alpha 7r iii film',
        'film foley library ',
        'film produktion regensburg ',
        'film regensburg ',
        'die heimreise film ',
        'fx film regensburg ',
        'film wien',
        'ab welchem jahr film mit ton',
        'grönemeyer musikvideo film',
        'foulis film ',
        'charlie sheen film ',
        'bg english film ',
        'liebe ist film',
        'flaggen film ',
        'glücksspirale film award ',
        'tarantino neuer film ',
        'daniel radcliffe neuester film ',
        'film award ',
        'film produktion ',
        'film musik ',
        'flimo film ',
        'fabian film ',
        'johnny depp film ',
        'anton corbijn film ',
        'film kino 2021 ',
        'dynamisches interview film ',
        'film intelligenter junge der zum psychologen geht ',
        'film dispo vorlage ',
        'film komparse ',
        'sony alpha 7r iii raw film ',
        'lift film ',
        'amazon prime film ',
        'tv spiel film ',
        'shutter fernseh film ',
        'film kleine weiße spots ',
        'shutter eindstellung fernseh film ',
        'coco film ',
        'film über depression',
        'film foley ',
        'film entwickeln',
        'hochzei film und foto ',
        'seven film '],
      faktenSuche2: ['corazza hotel hamburg ',
        'hotel krieger mariaort ',
        'hotel in inning am ammersee, 82266 ',
        'hotel saratoga mallorca',
        'hotel in pisa, italien ',
        'hotel in budapest, ungarn ',
        'hotel in pforzheim ',
        'hotel eder almenland ',
        'hotel turm augsburg ',
        'hotel in rust ',
        'hotel in der nähe von 41.910625,13.876946 ',
        'hotel in tarent, italien ',
        'hotel in bayern ',
        'hotel in regensburg',
        'hotel in riga ',
        'hotel vinzore rimini ',
        'hotel in der nähe von 41.899982,13.884136 ',
        'hotel in heidelberg ',
        'grand budapest hotel ',
        'hotel in münchen ',
        'hotel globo rimini ',
        'hotel höferer ',
        'hotel in der nähe von 36.302203,-116.414658 ',
        'hotel in grainet ',
        'bergwald hotel kötzting ',
        'europapark hotel ',
        'hotel in bad griesbach im rottal ',
        'hotel in 8333, österreich ',
        'hotel europapark ',
        'hotels in torre pali le, italien ',
        'hotel in salento ',
        'hotel ',
        'havana cuba hotel ',
        'park hotel 1970 ohrnbachtalstraße 7 64720 michelstadt-vielbrunn hessen ',
        'hotel in salento, quindío, kolumbien ',
        'hotel eder ',
        'hotel in moreton island, queensland 4025, australien ',
        'hotel in rom, italien ',
        'hotel in st. englmar ',
        'hotel in rom, italien ',
        'hotel in mailand, italien ',
        'hotel in regensburg ',
        'asara wine estate &amp; hotel reviews',
        'wellness hotel bayern 2 nächte ',
        'hamburg elbphilharmonie karten und hotel',
        'hotel in barcelona, provinz barcelona, spanien ',
        'santiago hotel rimini ',
        'hotel in soltau ',
        'hotel in (custom-location) ',
        'europapark hotel ',
        'rosendal winery and wellness retreat hotel',
        'tirol kitzbühel hotel schloss lebenberg ',
        'hotel riedlberg ',
        'hotel ',
        'flug und hotel ',
        'hotel dubai ',
        'hotel in 35307, provinz las palmas, spanien ',
        'hotel esplendido',
        'hotel in riga ',
        'hotel in neunburg vorm wald ',
        'hotel santiago rimini ',
        'was passiert wenn man im hotel nicht anreist ',
        'hotel in bayerbach bei ergoldsbach ',
        'trs turquesa hotel punta cana',
        'hotel weinstube löwen ',
        'hotel in regensburg',
        'hotel royal wien ',
        'hotel in villach, österreich ',
        'hotel santiago, viale taranto ',
        'hotel in niederbayern '],
      faktenSuche3: ['windows 98 ',
        'ääpc trans windows verschieben ',
        'windows 10 pro key ',
        'festplatte mit windows umwandeln ',
        'windows movie maker audio only',
        'windows 7 taskleiste ausblenden',
        'windows 10 umgebungsvariablen zurücksetzen ',
        'windows 10 touch rechtsklick ',
        'minecraft windows 10 edition eigener server ',
        'windows systemsprache ändern',
        'server verbinden windows ',
        'windows 7 update download',
        'windows 10 was passiert wenn der ram voll ist ',
        'age of empires 3 windows 10 ',
        'windows 10 umgebungsvariablen setzen ',
        'windows 10 tastaturlayout ändern ',
        'internet explorer lässt sich nicht starten windows 7',
        'festplatte formatieren windows 10 geht nicht ',
        'bootcamp windows 10 installieren ',
        'festplatte für mac und windows formatieren ',
        'apple bootcamp treiber windows 10 ',
        'windows use disk without installation ',
        'windows 10 taskmanager graphics_1 ',
        'windows 10 score ',
        'minecraft windows 10 mods ',
        'apple music windows 10 ',
        'minecraft windows 10 edition beta zurück auf stabel ',
        'coding on raspberry pi from windows ',
        'apple keyboard on windows 10 driver ',
        'windows partition manager ',
        'raid windows ',
        'windows booten zurücksetzen ',
        'reinstall windows ',
        'powershell script autostart windows 10 ',
        'apple keyboard wireless windows 10 ',
        'paste cmd windows 10 ',
        'minecraft windows 10 edition floating boat mod ',
        'windows 10 programm zeitversetzter autostart ',
        'windows 10 pro key ',
        'internet explorer lässt sich nicht starten windows 7',
        'windows 10 iso download ',
        'command prompt windows 10 commands ',
        'windows cpu temperatur ',
        'threadripper 1920x windows 10 bios optimieren ',
        'gedankenstrich windows ',
        'windows 10 startmenü ändern ',
        'set an alarm clock on windows 7',
        'windows 10 apple keyboard layout ',
        'windows 10 scripting ',
        'cmd prompt windows 10 commands ',
        'windows tastatur deaktivieren ',
        'windows power shell run on boot ',
        'age of empires 3 mac und windows lan ',
        'ssh windows 10 ',
        'windows 10 deutsch download ',
        'windows boot shortcuts ',
        'raid windows ',
        'apple wireless keyboard windows 10 volume control not working ',
        'windows 10 installation usb ',
        'windows 10 homeinstallation media ',
        'threadripper 1920x windows 10 bios ',
        'nitrado minecraft server switch java to windows 10 ',
        'focusrite scarlett 2i4 driver windows 10',
        'windows bitlocker umgehen ',
        'windows fehlercode 50 ',
        'windows 10 scorr ',
        'quicktime player windows 10 ',
        'hintergrund windows ',
        'windows festplatte formatieren ',
        'windows 10 nutzt nur bose mikrofon '],

      searches: ['casper lang lebe der tod ',
        'jerzy palacz ',
        'alles steht kopf englisch ',
        'covid-19 ',
        'led leucht röhren ',
        'augsburg studieren ',
        'rocky horror show ',
        'alter mafiafilm ',
        'sparkasse regensburg ',
        'factura ',
        'pinakothek der moderne barer straße münchen ',
        'flug ',
        'famous saying',
        'riesenteleskope alpaka ',
        'kendrick lamar\'s friend dave',
        'panama ',
        'ausgangssperre in bayern ',
        'lorazepam',
        'faustformel reaktionsweg ',
        '80 er jahre abendmode ',
        'klt münchen ',
        'ws2812b led ',
        'geprc fc für dji ',
        'breite tür ',
        'king size regensburg ',
        'hochzeits filmografie ',
        'immer noch ',
        'walhalla walhallastraße donaustauf ',
        'kisd integrated design ',
        'neuer bußgeldkatalog alkohol ',
        'bestäubt ',
        'seamless focus ring ',
        'neoprenanzüge ',
        'avocadostore ',
        'taschenkrebs ',
        'pfau ',
        'entweder ',
        'golfbekleidung',
        'edeka karl stieler straße ',
        'whatsapp foto von unbekannter nummer ',
        'kessler dolly ',
        'blender particle along mesh ',
        'österreich ',
        'samsung gear 360 glas ',
        'mozart in the jungle season 5 ',
        'hennessy beaute du siecle cognac',
        'can you speak spanish in brazil',
        'corporate font designer ',
        'bach rock band ',
        'spiele mit ü eiern gelbe ',
        'wenpod md2 kaufen ',
        'brauhaus stuttgart ',
        'fahrplan jetzt für heute für die linie 1 an feiertagen ',
        'gearo ',
        'friseur bellenhaus, holzbreiten, wenzenbach, regensburg ',
        'wacom ',
        'wetter ',
        'vst plugin bitcrusher',
        'brushed motor ',
        'friedrichshafen spritpreise ',
        'nvidia ',
        'camif: capture abort fehlermeldung phase one iq4 ',
        'wetter morgen ',
        'reifen vor ort',
        'indesign seitenzahl ab seite 3 ',
        'andreas muxel ',
        'harnsäure senken mit vitamin c und folsäure ',
        'linie 3 rvv ',
        'tikka paste zusammensetzung ',
        'fritzbox gastzugang löschen',
        'podologen in augsburg ',
        'zeichentablett mit tablet ',
        'dollar kurs ',
        'nba playoffs standings',
        'geißkopf ',
        'digitaler impfpass ',
        'maya texture input output ',
        'flywoo mr croc hd 5 inch ',
        'degerloch ',
        'kohlendioxid',
        'westbad regensburg ',
        'david chipperfield museum berlin ',
        'eminems wife',
        'gratis pronos ',
        'juz arena ',
        'eachine cvatar ',
        'wetransfer ',
        'safari ie9 ',
        'gearo ',
        '192.168.1.1/ ',
        'sky ',
        'fsd feuerwehr ',
        'wie viel ist 1 meter in yards ',
        'a300 ',
        'james bond',
        'bo3 zombies prestige emblems',
        'tascam dr40 internes mic verwenden ',
        'nordvpn account löschen ',
        'petaling street ',
        'meistverkaufte auto welt ',
        'kinder anzüge ',
        'hunkemöller ',
        'xs508m ',
        'vox werbung ',
        'musikinstrumente kaufen regensburg',
        'il volo germany ',
        'amazon music download ',
        'apfelkuchen mit haselnuss ',
        'amd 8 kern prozessor ',
        '57th grammy awards nominations',
        'oskar roehler selbstverfickung ',
        'herd austauschen ',
        'nagelbett ',
        'la scala von eschenbachgasse',
        'hundenamen ',
        'webgl bilder ',
        'unity hoch 2 ',
        'smatree spark ladestation ',
        'watts los angeles',
        'Akku Astbaumsäge',
        'kohlekraftwerk generator ',
        'x399 taichi motherboard realtek ',
        'air sex challenge ',
        'gopro quick release ',
        'luftblasen aus wasser entfernen ',
        'traueranzeigen deutschland',
        'philips eismaschine hr 2302 ',
        'iq4 software update 2019 ',
        'shuu afrikaans ',
        'europapark ',
        'sichspa ',
        'kameraverleih ',
        'haus der kleinen forscher ',
        'ju on the grudge',
        'boschenmeer golf',
        'mineralbäder stuttgart ',
        'spiele musik ',
        'vodafone service ',
        'paul leonard-morgan extraction action ',
        'apple application support ',
        'hp nwx360 ',
        'dino spruch ',
        'i7 4770 sockel ',
        'fachzusimpeln ',
        'gerbergasse 4 93047 regensburg ',
        'wetter ',
        'panikstörungen',
        'filme ab 16 komödien ',
        'wikipedia bier ',
        'wetter morgen ',
        'psychologiestudium module',
        'wetter morgen ',
        'homepod mini',
        'paragon cap ',
        'reportar ',
        'web mail ',
        'find a a&r',
        '9mm motor mount fpv ',
        'hochschule augsburg mail ',
        'letter of intend ',
        'come cuocere i cornetti surgelati ',
        'schulerloch ',
        'corona augsburg ',
        'synology drive ',
        'melancholic vs sad',
        'bäcker hedelfingen ',
        'mitgliedstaaten der eu',
        '7tv ',
        'peter pane augsburg ',
        'teig für apfelkücherl ',
        'css text-shadow ',
        'cancun ',
        'javascript ignore rotation ',
        'edu rent ',
        'tutorials ',
        'memrise',
        'lamina cribrosa auge ',
        'the weeknd belong to the world lyrics',
        'anamorphic filter ',
        'bwt dosierphosphat',
        'uni bib öffnungszeiten regensburg ',
        'makroringe ',
        'paladins benachrichtigungen erwetb ',
        'pana flaggen ',
        'nba schedule',
        'electric cars infographic ',
        'thingiverse ',
        'taare zameen par ',
        'ts2307 cannot find module \'react\' ',
        'hermes taschen ',
        'ikea regensburg@port01.com ',
        'lift musical arrangemen',
        'schweizer hof pasing',
        'mini kompressor ',
        'amd ryzen 7 2700x tdp ',
        'wie schnell darf man in england fahren ',
        'karoline herfurth ',
        'wetter morgen ',
        'ig metall mitgliedschaft kündigen ',
        'berg lusen wetter ',
        'gopro lite serie ',
        'guitar notes',
        'the weeknd in the night',
        'hotels in münchen ',
        'control ',
        'bmw bkk telefonnummer',
        'gc gut rieden',
        'can you get extra sentence for breaking out us',
        'q2 gewicht ',
        'vueling verlorenes gepäck',
        'bisafans',
        'wasserfilter glas ',
        'ferrari mieten ',
        'claude lelouch ',
        'schaller bäcker regensburg ',
        'wohin mit leeren druckerpatronen ',
        'orthopädische schuhe regensburg ',
        'retarded ',
        'wasserenthärtungsanlagen bwt',
        'sparda ',
        'wetter ',
        'bad abbach ',
        'unity linerenderer color ',
        'skribls ',
        'das bürgerbüro wie lange hat das offen machen die auch eine zulassung von kfz autos ',
        'aldisüd',
        'aqua lete ',
        'wahl amerika stand ',
        'pearlescent filter 1/4 77mm calumet ',
        'oculus quest kabel ',
        'yorkshire ',
        'amazo n de',
        'xxxtentacion the remedy for a broken heart lyrics',
        'assistenzsysteme auto ',
        'preternaturally',
        'dm jackfruit pasta ',
        'MOC München',
        'erinnerung an…',
        'use of present perfect',
        'web2py db sort by datetime ',
        'the vegetarian butcher ',
        'schlupp augsburger puppenkiste 4 ',
        '360 grad blender ',
        'sparda-bank ostbayern ',
        'drake bitch is crazy',
        'dreifelderwirtschaft',
        'life seems pointless',
        'hey chant sample',
        'zwanghaftes lügen ',
        'tablet mit usb htc ',
        'ps 4 ',
        'hs augsburg praxissemester interaktive medien ',
        'm2 ssd ',
        'mazda 2 nakama ',
        'wetter riga ende august ',
        'hintergrundgeschichte the mandalorian ',
        'spiel und übungsstunde mit der langbank sportler ',
        'atzenbrille ',
        'anton bauer ',
        'bürgerbüro hedelfingen ',
        'hobbyking ',
        'aral tankkarte steamgutschein ',
        'gayromeo ',
        'donau',
        'gewerbe anmelden',
        'lappersdorf kindergarten ',
        'web2py git push problems ',
        'homer singen will ich von aphrodite der züchtigen ',
        'ssd samsung t5 ',
        'stüssy laguna beach eau de toilette amazon ',
        'hip hop lps digital only',
        'e mount aps objektive af 1.1 blende ',
        'lens set jzofilm ',
        'android installieren ',
        'rezept für käsekuchen ',
        'hör auf mein spätzle ',
        'dji fpv fernsteuerung hülle ',
        'call a pizza regensburg',
        'canon 60 d ',
        'mavic air ',
        'bauhaus ',
        'woolman, matt: digital information graphics thames & hudson ',
        'ard mediathek herbert feuerstein ',
        'fc bayern gegen bvb ',
        'kena mobile ',
        'oz in ml ',
        'qd vodka ',
        'mz online ',
        'fa+ ',
        'sardinia watterfall pia ',
        'rvv linie 8 samstag ',
        'avv wochenkarte ',
        'github ',
        'wochenblatt regensburg ',
        'flüge von riga nach trf am 22.08.2021, rückflug am 25.08.2021 ',
        'film locations bayern ',
        'nick romeo reimann ',
        'robert koch institut corona reproduktionszahl ',
        'keba wallbox',
        'feiwillige feuerwehr wurtzelhofen ',
        'leengeschichte kochen ',
        'vitamix ',
        'stuttgart west straßenfest ',
        'sigma 50 -100 ',
        'whatsapp web ',
        'champions league tabelle ',
        'flixbus ',
        'kubsch ',
        'was heißt auf italienisch was machst du gerade ',
        'brenna durs gut lyrics ',
        'use übersetzung ',
        'kendrick lamar wesleys theory',
        'mömax ',
        'brillen.de kollektion',
        'garmin golfuhr s2 update',
        'mercedes nutzfahrzeuge ',
        'peugeot modelle ',
        'wetter ',
        'pc mit 4k grafikkarten ',
        'razer ',
        'mavic 3 ',
        'halloween kürbis ',
        'unity instantiate prefab ',
        'unity wheel collider brake ',
        'culdesac',
        'moser regensburg leoprechting ',
        'bildschirm kalibrieren ',
        'des öfteren ',
        'xkevingc call of duty',
        'from the depths helicopter tutorial ',
        'hofgutstraße 23 memmingen ',
        'walderdorff straße 7',
        'linux programme installieren ',
        'audi fensterkurbel ',
        'peoplesoft ',
        'names wordplay',
        'bmw schlüsselanhänger wo kaufen ',
        'schimon peres',
        'was ist in den kühlpacks drin ',
        'black ops trophäen leitfaden',
        'beats verkaufen',
        'unity pbr graph transparency ',
        'klebe bh hunkemöller ',
        'seychellen einreisebestimmungen',
        'impact free download ',
        'web.de',
        'lebensmittelvergiftung restaurant meersburg ',
        'unity visual effects graph spawn local stay world ',
        'wochenrechner ',
        'kneitinger garten regensburg ',
        'corona augsburg ',
        'hit boy box office',
        'institutsambulanz regensburg',
        'feiertage dezember ',
        'war machine',
        'maps ',
        'haubensak regensburg christbaumverkauf ',
        'person of transgender experience ',
        'rossini oder die mörderische frage wer mit wem schlief ',
        'sony alpha 6400 ',
        'slinging meaning',
        'synology cloud station sync on demand ',
        'carpenter',
        'taxi baumann r ',
        'wie nennt man man einen heizungsbau ',
        'münchner kreis trophy 2018',
        'wetter ',
        'spiel und freizeit gersthofen ',
        'earth 3d model free ',
        'mercedes benz wiki ',
        'blomberg waschmaschine bedienungsanleitung',
        'y',
        'bars in der nähe von prenzlau ',
        'dirk meycke ',
        'kaufering ',
        'hydrochlorid',
        'limousine mieten ',
        'notting hill',
        'stichsägeblatt fällt raus ',
        'bahn logo designer ',
        'gopro ',
        'itunes connect',
        'sony pxw-fs7 sensor size ',
        'wie ist das psychologiestudium aufgebaut',
        'after effects animated background ',
        'schlagbuchstaben metall ',
        'kickstarter ',
        'oscars 2015',
        'caldera de bandama parking ',
        'ist es grundsätzlich zulässig von den geltenden richtlinien abweichen auf italienisch übersetzen ',
        'bodensee fläche ',
        'c minor 3rd inversion',
        'ayda field ',
        'abel tesfaye',
        'huhn kcal ',
        'mobile ',
        'fta ',
        'wetter morgen ',
        'cast away ',
        'fernsehkoch christian ',
        'threadripper 1920x windows 10 bios ',
        'hennessy',
        'ps3 super slim 500gb anschlüsse ',
        'einbau spülmaschinen ',
        'kommode regensburg ',
        'book about stripers',
        'voucher deutsch ',
        'after effects shape follow path ',
        'kleine freunde kita ggmbh ',
        'o2 free ',
        'hs-augsburg ',
        'sushi bike regensburg ',
        'call of duty ghosts ps3 stats to ps4',
        'yout ',
        'rtx 3080 20 gb ',
        'daniel radcliffe net worth',
        '45a fc and esc ',
        'janine@dronesperhoure.de ',
        'stuttgart 21 ',
        'nitrado minecraft server switch java to windows 10 ',
        'cinemaxx ',
        's',
        'andreas muxel ',
        '53 65 61 72 63 68 20 66 6f 72 20 2f 20 67 65 65 6b 77 65 65 6b 20 6f 6e 20 59 6f 75 54 75 62 65 20 66 6f 72 20 61 20 74 72 69 70 20 62 61 63 6b 20 69 6e 20 74 69 6d 65 2e 20 38 29 ',
        'bg film ',
        'rewe degerloch ',
        'prioritäten visualdesign ',
        'ferguson shooting',
        'big sean i dont give a fuck bell notes',
        'goldener hirsch regensburg ',
        'holzbienen ',
        'talystmachine ',
        '1 unze gold ',
        'mvv e scooter ',
        'twitter api get all tweets with hashtag ',
        'caddx tarsier ',
        'baustoff kontor ',
        'wetter ',
        'wie viel passen wie viel leute passen in der arena von verona rein ',
        'one plus wallpaper ',
        'chefarzt',
        'the grillhouse ',
        'arsenal',
        'dollar euro ',
        'f1 ',
        'natürliche cholesterinsenker ',
        'mehrgenerationenhaus ',
        'klam ',
        'mini thermal printer + raspberry pi works for text, but gibb ',
        'buzzer beater',
        'dinkelacker ',
        'bauknecht wm mt 7 iv waschmaschine sehr laut ',
        'corniglia italien ',
        'delaire graff restaurant',
        'how many tenses in spanish',
        'asap rocky demons sample',
        'cr123a batterie ',
        'esc michael schulte',
        'tau ghostkeel rules ',
        'callaway epic max 2021 driver',
        'how much people can share ps4',
        'blackmagic pocket cinema 4k auf ronin s wechselplatte ',
        'nzxt x52 ',
        'adopt a child as a single man canada',
        'äquivalente ',
        'jahn und jahn ',
        'bw bank prepaid kreditkarte aufladen ',
        'mobile de pkw',
        'po ',
        'ntv live',
        'ghosts prestige 3',
        'gc eschenried',
        'döner ',
        'quadrum productions wixsite ',
        'look forward to work with you too',
        'sky go',
        'age of empires 3 lan party ',
        'ltn airport',
        'viessmann solar störung 9e',
        'nute farbe pumps ',
        'pfändungsfreibetrag pflegeversicherung',
        'siemens staubsaugerbeutel ',
        'huk regensburg ',
        'support blackmagic ',
        'weisheitszahn schmerzen was tun ',
        'smile enemy quotes',
        'dj antoine regensburg ',
        'wetter morgen ',
        'star 67 genius',
        'markus lanz',
        'ebay ',
        'g hub profile ',
        'zusammenspiel ressourcen und verbrauch ',
        'zdf neo royal ',
        'das huf ',
        'scania k113 bus ',
        'singapur kaugummi',
        'craigslist',
        'easy ',
        'corsica ',
        'ulrichshof ',
        'words that describe relationships',
        'google drive pin file to top in browser ',
        'mynikon ',
        '124 fahrenheit in celsius',
        'bischof henle straße regensburg ',
        'blender eevee transparent material ',
        'grundstücke pentling ',
        'dr.-franz-schmitz-straße ',
        'axii ii lr ',
        'cinemaxx ',
        'unity led board ',
        'wie weiß ich das ich positiv getestet wurde ',
        'momma knows best ',
        'mondfinsternis',
        'mandarinen ',
        'gear fit ',
        'as for me meaning',
        'dollar euro rechner ',
        'ki stimmung analysieren ',
        'https://www.happycar.de/',
        'caldera de bandama ',
        'spiele zum thema jahreszeiten ',
        'db',
        'walimex pro waver gimbal für gopro ',
        'hdmi micro adapter ',
        'space engineers silver ',
        'manuel reef marina',
        'münchen meeresspiegel ',
        'zoll in cm ',
        'inner ',
        'eros ramazzotti 2017 deutschland ',
        'dhcp ',
        'unity limit skylight by distance ',
        'mini gurken glas ',
        'IPhone gehäuse defekt',
        'geprc mark 4 ',
        'bewerbungsfoto',
        'blender cycles rubber ',
        'rihanna wiki ',
        'sportnahrung ',
        'übersetzer ',
        'dominx.de',
        'anstoß jahn ',
        'blender ',
        'asa',
        'porno video gratis ',
        'network ',
        'kabel receiver c2',
        'svnet Gefahrenstelle',
        'nes roms',
        'is it good to learn two languages at once',
        'voll autonomen mobilität ',
        'netcarshow ',
        'volksbank ',
        'thermaltake pure 20 lüfter 800rpm 3-polig anschließen ',
        'automower 105',
        'tiger ',
        'lenovo yoga 310 11 review ',
        'husqvarna',
        'media markt',
        'garbo regensburg ',
        'große holzbiene ',
        'fucks deutsch ',
        'motorleistung reduziert opel astra ',
        'movie2k ',
        'myelin sheath',
        'no sé pero te amo',
        '5he weeknd im getting high',
        'Ampel 220V',
        'techno club berlin sisyphos ',
        'web2pi git ',
        'nabucco oper handlung',
        'auto punkt aus usa in deutschland ',
        'wayfair ',
        'mz regensburg ',
        'arteria hyaloidea ',
        'aldi chia samen ',
        'maschinen menschen ',
        'online telefonbuch ',
        'portland oregon ',
        'airportliner regensburg ',
        'opernsänger carreras ',
        'regensburg dult',
        'brunch im hilton münchen',
        'haus der 150 biere ',
        'loriot ',
        'sometimes you couldn\'t change ',
        'lolo ferrari ',
        'hotels in regensburg ',
        'helmut newton fassbinder ',
        'hiendl aschheim ',
        'late-night-essen ',
        'was war die hauptstadt von deutschland vor berlin ',
        'flüge von cgn nach rix am 08.10.2021, rückflug am 17.10.2021 ',
        'bmw 202 ',
        'grillen bei burger king werbung ',
        'sali design regensburg ',
        'flex seal tape ',
        'trey songz me 4 u infidelity 2 lyrics',
        'jbl sommer 10% ',
        'wie lang ist ein tag auf der venus',
        'conrad ',
        'puerto de soller',
        'focusrite scarlett 2i4 driver windows 10',
        'ein achs gimbal für gopro ',
        'unstetiges verhalten ',
        'html line ',
        'mr onions augsburg ',
        'biggest r&b artists',
        'banggood versand ',
        'was fliegt gerade ',
        'embedding unity webapp ',
        'ebay ',
        'minecraft erweiterte einstellungen ',
        'hot tub ',
        'indische zucchini ',
        'maße vw caravelle ',
        'ready to mingle deutsch ',
        'contrary to ',
        'matilda ',
        'zeichnen gruppen spiel ',
        'a3 dimensions ',
        'fuq.coom ',
        'sony bridgekamera rx10 m4 ',
        'shrimps zubereiten ',
        'freya ridings ',
        'aus was besteht ein natursauerteig ',
        'making money off the internet',
        'antidepressants abuse',
        'bmw 750i ',
        'patzak sinzing ',
        'kabel englisch ',
        'pdf u3-handreichung zum baybep ',
        'treno 87 15 bologna centrale lecce il 03 08 2020 ore16, 45da quale binario parte ',
        'spark the explorer ',
        '4 nd filter ',
        'string ',
        'tipico',
        'dänemark barkje in ',
        'canon ae-1 program mount ',
        'condor gepäckbestimmungen ',
        'almouj golf course',
        'tbs unify pit mode ',
        'soundcloud ',
        'positives selbstkonzept fördern ',
        'coffee for your head ',
        'angela merkel vor 20 jahren ',
        'slletesten regensburg ',
        'ludwig xiv',
        'Hannelore Gehlig',
        'bla bla car bar statt paypal bezahlen ',
        'windows bitlocker umgehen ',
        'cartoon & cereal genius',
        'kl ',
        'bayerisches wa ',
        'art of wedding ',
        'firefox addon convert2mp3',
        'schützenverein zur grünen au oberisling ',
        'bayernwerk netz',
        'last minute urlaub ',
        'rsc2 mieten ',
        'wlan lichtschalter doppelrahmen ',
        'most valuable education country',
        'lebenslauf ',
        'hotel petrus pustertal',
        'als',
        'was ist ein produkt ',
        'samsung gear s3 ',
        'penis rötung ',
        'champions league ',
        'minecraft mandalorian skin ',
        'bayerwaldhof preise 2019 ',
        'gc hohenpähl',
        'busted ',
        'google pay ausland ',
        'hasit 704 preis ',
        'winkel aus richtungsvektor ',
        'playmobil ',
        'san francisco tagesticket ',
        'folys ',
        'autoscout24',
        'netto rechner',
        'f. rechlin gedichte ',
        'fpv antennen typen ',
        'blow up münchen ',
        'unity image as background ',
        'shirin david ',
        'lotto ',
        'räumliche trennung ',
        'southside 2016 ',
        'din a2 maße ',
        'mass storage ',
        'sieben film auszeichnungen ',
        'größten umweltverschmutzer ',
        'antenne bayern ',
        'amphetamine vs ecstasy',
        'was muss ich beim verlegen von Lankabeln beachten',
        'kika live ',
        'marc neumeister crew united ',
        'cpc ',
        'cnhl ',
        'monetarisierung youtube ',
        '250 g drohne ',
        'telefon klingelschild ',
        'ebay ',
        'shocking shorts award 2014',
        'wholesome ',
        'p+r freiham ',
        'siemens telefon gigaset',
        'quadro rtx 4000 multi gpu ',
        'maßliebchenstrasse 12b ',
        'unity scene load find gameobject in another scene ',
        'ipad mockup ',
        'currywurst youtube wiki ',
        'arduino pin power output ',
        'swinger club ',
        'kai pflaume ',
        'yuneec typhoon g gewicht ',
        'spark mieten ',
        'vodafone email ipad einrichten',
        'toms deutschland gutscheincode ',
        'dbmonatskarte ',
        'hochschule augsburg zwirbl ',
        'produkt ',
        'hs augsburg semestertermine ',
        'google ',
        'webcam brennberg ',
        'dji goggles re ',
        'drone sim ve ',
        'basslet ',
        'love really hurts without you ',
        'aldi reisen ',
        'fujifilm x pro3 ',
        'samy deluxe - halt dich gut fest ',
        'ips panel ',
        'unity instantiate overwrite parent position ',
        'kultusministerium bayern ',
        'formatierte speicherkarte wiederherstellen chip mac ',
        'oecd länder liste',
        'yuneec schraubenset ',
        'radix sort ',
        'flechtfrisuren mittellange haare ',
        'skyrim gegenstände cheaten ',
        'skyrim ',
        'haimerl rolf ',
        'tonmann ',
        'meme ',
        'leon goretzka ',
        'fantasia cut up ',
        'chatroulett',
        'kleiner reisebus mieten münchen ',
        'it wonderful i dream of you remix ',
        'gbr umsatzsteuer id ',
        'drunk in love the weeknd lyrics',
        'schnelltestzentrum regensburg media markt ',
        'lps to pre order',
        'latitude longitude london ',
        'arduino baukasten ',
        'xp pen artist 15.6 pro macbook pro usb c ',
        'sixt oldtimer ',
        'akzidenz grotesk ',
        'lady gaga alba berlin',
        'media markt ',
        'amazon ',
        'asus ',
        'geis ',
        'dollar kurs ',
        'v kurzechen ',
        'outlook ',
        'uhlenhaut coupe ',
        'stylized muscle car ',
        'minecraft buch ',
        'wappen bayern ',
        'wandern sprüche ',
        'fähre wangerooge ',
        'riedenburg ',
        'ritalin',
        'gear 360 lens ',
        'kindergeldauszahlung',
        'senf ',
        'elektro weigl ',
        '100 mm makro cene linse ',
        'originale ',
        'betaflight empfänger ohne copter darstellungen ',
        'zum alten tor ',
        'spielerisch lernen ',
        'houdini get geo name ',
        'kraftklub tour 2020 ',
        'one plus 6 ',
        'wetter morgen ',
        'wer ist letztes jahr in der bundesliga meister geworden ',
        'taranis q x7 ',
        'bayreuth',
        'gearo ',
        'quixx deluxe wo kaufen ',
        'bart färben',
        'amtsgericht münchen grundbuchauszug',
        'cinemaxx regensburg ',
        'hütte mieten bayern ',
        'stierhakler ',
        'fpv ',
        'internship with intuity media lab in stuttgart ',
        'zähler und nenner',
        'es netflix entfernt ',
        'ein wert von 1 euro ',
        'haarlase',
        'nba finals',
        'dji osmo action ',
        'abit jahresfortgangsnoten',
        'dollar in euro ',
        'einheitsvektor berechnen ',
        'vw vento ',
        'playmassive wiki ',
        '2pac bet awards',
        'canon 6d ',
        'erzieher gehalt ',
        'browser games with friends ',
        'bilderrahmen regensburg ',
        'olympus m.zuiko digital objektiv 17mm ',
        'dji ronin s focus pullen ',
        'fpv pid zittern im sinken ',
        'festgelegte  reihenfolge ',
        'die wimshurst-maschine ',
        'joghurt fester machen ',
        'alexa show 5 ',
        'autel evo 2 pro price ',
        'georg rail ',
        'asap rocky ghetto symphony lyrics',
        'wig schweißen gas liste ',
        'south african airways',
        'aufbau der brust ',
        'innolab hs augsburg sandra ',
        'yeliz koc nude ',
        'pizzeria regensburg arnulfsplatz ',
        'iphone 6s benchmark antutu ',
        'blue yeti x arm ',
        'ebay kleinanzeigen ',
        'happy birthday schrift ',
        'mp feuer ',
        'garmin routenplaner',
        'corona augsburg ',
        'dr. barbara teltschik ',
        'flüge von münchen nach brindisi am 01.08.2017 ',
        'ryanair münchen',
        'animation ameise ',
        'messe stuttgart ',
        'wie viel kostet der bellini beim aldi der ist diese woche in angebot bellini pfirsich ',
        'nagel mit kunststoffkopf ',
        'wetter morgen ',
        'southside 2016 ',
        'installing battery 3 without cd',
        'asap rocky fucking problems genius',
        'fall out cheat ',
        'sony ael ',
        'giacomo celentano ',
        'auslands bafög wikipedia',
        'pumuckl ',
        'hailie jade scott',
        'post tracking ',
        'angela merkel frau bundeskanzlerin für tvnow ',
        'p5js examples ',
        'wetter münchen ',
        '255 50 r19 sommerreifen',
        'fighting for death dnf films ',
        'itunes wma to mp3',
        'absa bank Hauprniederlassung south africa',
        'hypovereinsbank login',
        'smoke stopper (xt60) ',
        'samsung gear s2 ',
        'golfgepäck south african airline',
        'desktop unterlage ',
        'ps3 kostenlos spiele für psn+',
        'was heißt betonstein rinnen auf italienisch ',
        'praktikumsstelle ',
        'casino tschechien american chance ',
        'kid cudi balmain jeans',
        'wasserenthärtungsanlagen',
        'j cole power trip lyrics',
        'kinderturnen übungen ',
        'bayreuth nach bischofsgrün',
        'spice bar münchen ',
        'rvv linie 1 sonntag ',
        '2er weltkrieg ',
        'dashboards ',
        'tilta float ',
        'obi stuttgart ',
        'maxima typo ',
        'tagesticket rvv ',
        'leuchtkübel wasser ',
        'cvd beschichtung ',
        'blender mac download ',
        'bkk krones ',
        'protzenweiher brücke regensburg ',
        'bridge ',
        'urlaubsguru ',
        'spontane aggression ',
        'ascap',
        'market ',
        'imdb interstellar',
        'merck ',
        'barbara stadler stadt regensburg ',
        'tofu taschen ',
        'leverano riserva vecchia torre ',
        'mac el capitan download dmg ',
        'steuerklasse',
        'regensburg aktuell feuerwehr ',
        'his portal ',
        'dart begriffe englisch ',
        'rtx 2070 asus ',
        'rufus download ',
        'silberberg ',
        'eibenweg 2, 93055 ',
        'flüge von san diego nach stuttgart am 10.03.2020, rückflug am 16.03.2020 ',
        'portrait drohne ',
        'sketch plugin ',
        'flüge von münchen, deutschland nach brindisi, italien am 04.02.2017, rückflug am 11.02.2017 ',
        'am sonntag um 0:30 uhr bis sechs leute ',
        'storia infinita canzone ',
        'alternatives wort für arbeitsalltag ',
        'partynextdoor',
        'burger ',
        'reason synonym',
        'sonic 4k aktion ',
        'längen und breitengrad berechnen ',
        'allgemeines hygienekonzept ',
        'garagentorsteuerung mercedes benz funktioniert nicht',
        'avtr cg video ',
        'kaufmich.com',
        'tus fit ',
        'vfb stuttgart ',
        'christian westphalen ',
        'australien',
        'system stresstest ',
        'in toronto studieren',
        'julien bam weihnachtsmann song ',
        'white ink music',
        'nba schedule',
        'drohne genehmigung karibik ',
        'servus ticket ',
        'milwaukee ',
        'pasta salsiccia e ricotta fresca ',
        'apple airpods pro case kaufen apple ',
        'kabelbox vodafone portweiterleitung ',
        'wetter morgen ',
        'fast and furious 6',
        'master studium projektmanagement ',
        's-bahn fahrplan herrsching münchen',
        'erdbeeren pflanzen ',
        'vr projekte ',
        'keba wallbox 11kw',
        'milch abpumpen wieviel ml ',
        'j edward',
        'wmf pfanne profi select 28',
        'lappersdorf kindergarten ',
        'konkrete kunst ',
        'fpv insta360 r ',
        'haarlaser männer ',
        'fl studio unsaved project',
        'amberg ',
        'dner ',
        'shop apotheke',
        'emuparadise',
        'ikea ',
        'konrad adenauer karikatur erklärt',
        'vw autonomes auto ',
        'haunt u lil peep lyrics',
        'webgl  phong ',
        'strawberry golf 2012',
        'feiertage dezember 2020 bayern ',
        'tatar ',
        'in design viele kreis nachträglich verändern ',
        'regenschirm englisch',
        'florian kapp stuttgart tot ',
        'gruber reisen',
        'jahn regensburg 25.11 ',
        'warcraft 3 reforged skin korg ',
        'bialetti für induktionsherd ',
        'wc ',
        'konglomerat ',
        'nitro rg240ybmiix test ',
        'flüge von münchen, deutschland nach brindisi, italien am 13.03.2017 ',
        'wohnen ',
        'entstehung nahostkonflikt',
        'flüge von muc nach ? am 30.05.2017, rückflug am 03.06.2017 ',
        'jp ',
        'kernen im remstal plz ',
        'hotels augsburg ',
        'filmlocation mieten ',
        'usa radio werbung ',
        'unity create new prefab from script ',
        'vimeo ',
        'wacom intuos pro l ',
        'hero 6 ebay ',
        'be instead of is',
        'nae nae meaning',
        'schlüsselbund mac',
        'idee und ansatz ',
        'unity zwischen nach video szenen wechseln ',
        'advanced warfare ps3 stats to ps4'],

      film: require('@/assets/media/digitSelf.mp4'),
      timeline: 0,
    };
  },
  beforeUnmount() {
    this.timeline.kill();

  }

};
</script>
<style scoped>
.btn {
  color: white;
  border: 3px solid;
  border-image-slice: 1;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
}

.col {
  margin: 2vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

#film {
  position: fixed;
  top: 0;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.backNew {
  position: absolute;
  z-index: 3;
  width: 100%;
}

#platzhalter {
  min-height: 150vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.container-fluid {
  min-height: 100vh;
  background: black;
  display: flex;
}

#soundbutton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 50px;
  width: 50px;
  z-index: 5;
  font-size: 42px;
  transition: opacity 2s ease-in-out;
}

#soundbutton:hover {
  cursor: pointer;
}

.unsichtbar {
  opacity: 0;
}
.anweisung {
  text-align: center;
}
</style>
